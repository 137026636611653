import { loadStripe } from '@stripe/stripe-js';

import hostname from './hostname';

let stripeKey = 'pk_live_bxM8GHvPK31fA9IEOuObdbxm';
if(window.location.host.toUpperCase().includes('LOCALHOST')
    || window.location.host.toUpperCase().includes('127.0.0.1')
    || window.location.host.toUpperCase().includes('192.168.1.200')
    || window.location.host.toUpperCase().includes('DEV.FUELSERVICE.ORG')
    ) {
    stripeKey = 'pk_test_YVr1nFOHTeX98HjWIUGLFKcm';
    console.log('*** USING TEST STRIPE KEY ***');
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
export const stripePromise = loadStripe(stripeKey);


export const checkout = async (sessionId) => {
    return new Promise(async (resolve, reject) => {
        // Call your backend to create the Checkout session.
        // const session = await fetchCheckoutSession(currency, quantity, recurring);

        const stripe = await stripePromise;

        // When the customer clicks on the button, redirect them to Checkout.
        const {error} = await stripe.redirectToCheckout({
            sessionId: sessionId
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        if (error) {
            console.log(error.message);
            resolve(false);
        } else {
            resolve(true);
        }

    });
};

export const fetchCheckoutSession = (currency, quantity, recurring, metadata) => {
    return new Promise(async (resolve, reject) => {

        const redirectHostname = window.location.protocol + '//' + window.location.hostname + (window.location.port !== "" ? `:${window.location.port}` : '');
        
        fetch(`https://${hostname}/api/stripe/createSession`,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({currency, quantity, recurring, metadata, hostname: redirectHostname}) // body data type must match "Content-Type" header
          })
        .then(response => response.json())
        .then(data => resolve(data));
    });
}