import React from 'react';

import AppStateContext from '../AppStateContext';
import TextInput from './FormComponents/TextInput';
import SelectInput from './FormComponents/SelectInput';

export class Details extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div>
                        <div className="signup-main-title">Confirm the station name &amp; brand</div>
                        <div>
                            <TextInput disabled={!appState.siteSettings.permissions.changeName} label="Name" value={appState.station.name} onChange={event=>appState.updateStationKey('name', event.target.value)} />
                        </div>
                        <div>
                            <SelectInput disabled={!appState.siteSettings.permissions.changeBrand} label="Brand" value={appState.station.brandid} onChange={event=>appState.updateStationBrand(event.target.value)}>
                                {appState.brands.map(brand =>
                                    <option key={brand.brandid} value={brand.brandid}>
                                        {brand.brand}
                                    </option>
                                )}
                            </SelectInput>
                        </div>
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Details.contextType = AppStateContext;
export default Details;    