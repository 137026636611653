const ServiceTypes = require('./ServiceTypes.json');

const ServiceAvailable = (station) => {

    const availableTypes = [];

    for(let i=0; i < ServiceTypes.length; i++) {
        if(station[ServiceTypes[i].key] === 1) {
            availableTypes.push(ServiceTypes[i].label);
        }
    }
    
    return(availableTypes);

}

export default ServiceAvailable;