import React from 'react';

import AppStateContext from '../../AppStateContext';

import Time from './Time';
import Popup from 'reactjs-popup';

import './ChangeTime.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ChangeTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opentime: '00:00:00',
            closetime: '23:59:59'
        };
    }

    save() {
        this.context.setOpenClosed(this.props.day, this.state.opentime, this.state.closetime);
    }

    render() {
        const {day} = this.props;
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                    <Popup
                    trigger={<button className="opening-change-button"><FontAwesomeIcon icon={["fal", "edit"]}/></button>}
                    modal
                    nested
                    onOpen={()=>this.setState({opentime: appState.station[day + 'open'], closetime: appState.station[day + 'close']})}
                    >
                    {close => (
                      <div className="change-time-modal animate__animated animate__slideInUp">
                        <div className="change-time-modal-header"> Change Opening Time</div>
                        <div className="change-time-modal-content">
                            <Time
                                key={day}
                                day={day}
                                openTime={this.state.opentime} 
                                setOpenTime={(time) => this.setState({opentime: time})}
                                closeTime={this.state.closetime}
                                setCloseTime={(time) => this.setState({closetime: time})}
                                setOpenClosed={(open, closed) => this.setState({opentime: open, closetime: closed})}
                                />
                            
                            <div className="change-time-actions">
                                <button
                                    className="change-time-cancel-button"
                                    onClick={() => {
                                    close();
                                    }}>
                                    Cancel
                                </button>
                                <button
                                    className="change-time-save-button"
                                    onClick={() => {
                                    close();
                                    this.save();
                                    }}>
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                    )}
                  </Popup>
                )}
            </AppStateContext.Consumer>
        );
    }
}

ChangeTime.contextType = AppStateContext;