import React from 'react';

import AppStateContext from '../AppStateContext';

import AwesomeSlider from 'react-awesome-slider';
import { Link } from 'react-router-dom';

import FindStation from './FindStation';
import SelectStation from './SelectStation';
import Details from './Details';
import Phone from './Phone';
import Address from './Address';
import Fuel from './Fuel';
import Opening from './Opening';
import Payment from './Payment';
import Services from './Services';
import Finish from './Finish';

import SignUpNavButtons from './SignUpNavButtons';

import './SignUp.css';
import GetUser from './GetUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LogoImage = require('../Home/Images/logo-white.svg');
const MaxolLogoImage = require('../Home/Images/Maxol-no-background.svg');

export class SignUp extends React.Component {

    componentDidMount() {
        this.context.checkCancel();
    }

    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (

                <div className="signup-page animate__animated animate__fadeIn">
                    <div className="signup-topbar">
                        <div className="signup-top-bar-logo-container">
                            <img className="signup-top-bar-logo hide-mobile" src={LogoImage} alt="fuelService Logo"/>
                            <img className="signup-top-bar-logo" src={MaxolLogoImage} alt="Maxol Logo" />
                        </div>
                        <Link className="signup-top-bar-cancel-button" to='/'> <span className="hide-mobile">return&nbsp;&nbsp;</span> <FontAwesomeIcon icon={["fal", "share"]} transform="flip-h"/></Link>
                    </div>
                    <div className="signup-slider-container">
                        <AwesomeSlider
                                name="animate__animated animate__signup-slider"
                                infinite={false}
                                buttons={false}
                                selected={appState.screen}
                                bullets={false}
                                onTransitionEnd={(event)=>appState.setScreen(event.currentIndex)}
                                fillParent={true}
                                onFirstMount={(event)=>appState.setSlideDetails({numberOfSlides: event.slides, screen: event.currentIndex})}
                                // onTransitionEnd={(event)=>appState.setState({selectedSlide: event.currentIndex})}
                            >
                            <div><FindStation /></div>
                            <div><SelectStation /></div>
                            <div><Details /></div>
                            <div><Address /></div>
                            <div><Phone /></div>
                            <div><Fuel /></div>
                            <div><Opening /></div>
                            <div><Payment /></div>
                            <div><Services /></div>
                            <div><GetUser /></div>
                            <div><Finish /></div>

                        </AwesomeSlider>
                    </div>
                    <div className="signup-bottombar">
                        <SignUpNavButtons
                            numberOfSlides={appState.numberOfSlides}
                            selectedSlide={appState.screen}
                            gotoNext={appState.nextScreen}
                            gotoPrevious={appState.prevScreen}
                            nextDisabled={appState.screen === 9 && !appState.emailValid}
                        />
                    </div>
                </div>
                   
                )}
            </AppStateContext.Consumer>
        );
    }
  }

SignUp.contextType = AppStateContext;
export default SignUp;    