const FuelTypes = require('./FuelTypes.json');

const FuelAvailable = (station) => {

    const fuelTypes = FuelTypes[station.country] ? FuelTypes[station.country] : FuelTypes.default;

    const availableTypes = [];

    for(let i=0; i < fuelTypes.length; i++) {
        if(station[fuelTypes[i].key] === 1) {
            availableTypes.push(fuelTypes[i].label);
        }
    }
    
    return(availableTypes);

}

export default FuelAvailable;