import React from 'react';

import "./SelectInput.css";

const SelectInput = (props) => {

    const {label, hint, children, ...other} = props;
    return (
        <div className="select-input-container">
            {label && <label className="select-input-label">{label}</label>}
            <div className="select-input-right-container">
                <select className="select-input-select" {...other}>
                    {children}
                </select>
                {hint && <div className="select-input-hint">{hint}</div>}
            </div>
        </div>
      
    );

}

export default SelectInput;