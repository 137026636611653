import React from 'react';
import TimeItem from './TimeItem';

import './Time.css';

export class Time extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            day: props.day.charAt(0).toUpperCase() + props.day.substr(1),
            prevOpenTime: props.openTime,
            prevCloseTime: props.closeTime,
        }

        this.setOpenTime = this.setOpenTime.bind(this);
        this.setCloseTime = this.setCloseTime.bind(this);
    }

    setOpenTime(time) {
        this.props.setOpenTime(time);
        this.setState({prevOpenTime: time});
    }

    setCloseTime(time) {
        this.props.setCloseTime(time);
        this.setState({prevCloseTime: time});
    }

    clickClosed() {
        if(this.props.openTime === '00:00:00' && this.props.closeTime === '00:00:01') {
            this.props.setOpenClosed(this.state.prevOpenTime, this.state.prevCloseTime);
        } else {
            this.props.setOpenClosed('00:00:00', '00:00:01');
        }
    }

    click24h() {
        if(this.props.openTime === '00:00:00' && this.props.closeTime === '23:59:59') {
            // this.setOpenTime(this.state.prevOpenTime);
            // this.setCloseTime(this.state.prevCloseTime);
            this.props.setOpenClosed(this.state.prevOpenTime, this.state.prevCloseTime);
        } else {
            // this.setOpenTime('00:00:00');
            // this.setCloseTime('23:59:59');
            this.props.setOpenClosed('00:00:00', '23:59:59');
        }
    }

  render() {
    return (
      <div className="time-main-container">
        <div className="time-day">{this.state.day}</div>
        <TimeItem 
            disabled={(this.props.openTime === '00:00:00' && this.props.closeTime === '00:00:01') || (this.props.openTime === '00:00:00' && this.props.closeTime === '23:59:59')}
            time={this.props.openTime} setTime={this.setOpenTime} />
        <TimeItem
            disabled={(this.props.openTime === '00:00:00' && this.props.closeTime === '00:00:01') || (this.props.openTime === '00:00:00' && this.props.closeTime === '23:59:59')}
            time={this.props.closeTime} setTime={this.setCloseTime}
        />
        <div className="time-options-container">
            <div className="time-options-item">
                <div className="time-options-label">24 Hour</div>
                <input
                    type="checkbox" 
                    checked={(this.props.openTime === '00:00:00' && this.props.closeTime === '23:59:59')}
                    onChange={()=>this.click24h()}
                />
            </div>
            <div className="time-options-item">
                <div className="time-options-label">Closed</div>
                <input
                    type="checkbox"
                    checked={(this.props.openTime === '00:00:00' && this.props.closeTime === '00:00:01')}
                    onChange={()=>this.clickClosed()}
                />
            </div>
        </div>
      </div>
  )}
}

export default Time;