const PaymentTypes = require('./PaymentTypes.json');

const PaymentAvailable = (station) => {

    const availableTypes = [];

    for(let i=0; i < PaymentTypes.length; i++) {
        if(station[PaymentTypes[i].key] === 1) {
            availableTypes.push(PaymentTypes[i].label);
        }
    }
    
    return(availableTypes);

}

export default PaymentAvailable;