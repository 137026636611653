import React, {Component} from 'react';
import * as Stripe from './services/stripe';
import * as Stations from './services/stations';
import * as PhoneNumber from './services/phoneNumber';
import { getCurrencyFromCountry } from './services/currency';

import GetSiteSettings from './services/siteSettings';

const AppStateContext = React.createContext();

export class AppStateProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: 0,
      brandid: 'maxol',
      email: '',
      emailValid: false,
      idstations: '',
      recurring: 'yearly',
      welcomePack: '',
      quantity: 1,
      location: {lat: 0, lng: 0}, // the location searched for
      station: null, // the current station record
      stations: [], // list of stations found
      brands: [],
      phoneOther: '',
      lastSignedUp: null,
      siteSettings: GetSiteSettings(),
      stripeError: null
    };

  }

  async componentDidMount() {
    let brands;
    do {
      brands = await Stations.getBrands();
    } while (brands === false);

    this.setState({brands});
  }

  async checkout() {
      const currency = getCurrencyFromCountry(this.state.station.country);
      // Get a session and store it incase they cancel
      const session = await Stripe.fetchCheckoutSession(currency, this.state.quantity, this.state.recurring, {idstations: this.state.idstations, station: this.state.station, email: this.state.email, welcomePack: this.state.welcomePack});
      
      if(session.error) {
        this.setState({stripeError: session.error});
        return;
      }

      const state = JSON.parse(JSON.stringify(this.state));
      delete state.brands;
      delete state.stations;
      window.sessionStorage.setItem(session.id, JSON.stringify(state));

      Stripe.checkout(session.id);
  }

  checkCancel() {
    const session = this.getPreviousSession();
    if(session) {
      this.setState(session);
      return(true);
    }
    return(false);
  }

  getPreviousSession() {
    // see if there is a sessionid ?session_id= or no session stored
    if(!window.location.search.startsWith('?session_id=')) {
      return(false);
    }

    const sessionId = window.location.search.replace(/\?session_id=/g,'');
    let savedState = window.sessionStorage.getItem(sessionId);
    if(savedState) {
      return(JSON.parse(savedState));
    }
    else {
      // console.log('no match for previous session');
      return(false);
    }
  }

  async setLocation(location) {
    let stations;
    do {
      stations = await Stations.findStations(location.lat, location.lng, this.state.siteSettings.searchRadius, this.state.siteSettings.brandid, 10);
      // console.log(stations);
    } while (stations === false);

    if(stations.length > 0) {
        this.setState({location, stations, screen: 1});
        // console.log(stations);
    }
  }

  selectStation(idstations) {
      this.state.stations.forEach(async station => {
          if(station.idstations === idstations) {
              this.setState({station, screen: 2});
              station.phoneParsed = await PhoneNumber.parsePhoneNumber(station.phone1);
              if(station.phoneParsed.valid) {
                station.phone1 = station.phoneParsed.nationalFormat;
              }
              // console.log(station);
              this.setState({station});
          }
      })
  }

  updateStationBrand(brandid) {
    this.state.brands.forEach(brand => {
        if(brand.brandid === brandid) {
            const updatedStation = JSON.parse(JSON.stringify(this.state.station));
            updatedStation.brand = brand.brand;
            updatedStation.brandid = brand.brandid;
            this.setState({station: updatedStation});
        }
    })
  }

  updateStationKey(key, value) {
      const updatedStation = JSON.parse(JSON.stringify(this.state.station));
      updatedStation[key] = value;
      this.setState({station: updatedStation});
  }

  setOpenClosed(day, open, closed) {
    const updatedStation = JSON.parse(JSON.stringify(this.state.station));
    updatedStation[day + 'open'] = open;
    updatedStation[day + 'close'] = closed;
    this.setState({station: updatedStation});
  }

  prevScreen() {
    if(this.state.stations.length === 0 && this.state.screen === 2) { // if its from a cancelled purchase there won't be any stations
      this.setState({screen: 0}) // so skip to the first screen
    } else {
      this.setState({screen: this.state.screen - 1})
    }
  }

  nextScreen() {
    this.setState({screen: this.state.screen + 1})
  }

  success() {
    const session = this.getPreviousSession();
    if(session) {
      this.setState({lastSignedUp: JSON.parse(JSON.stringify(session.station)), station: null,});
    }
  }

  render() {
    return (
      <AppStateContext.Provider
        value={{
          setSlideDetails: (slideDetails) => this.setState(slideDetails),
          screen: this.state.screen,
          numberOfSlides: this.state.numberOfSlides,
          prevScreen: () => this.prevScreen(),
          nextScreen: () => this.nextScreen(),
          setScreen: screen => this.setState({screen: screen}),

          location: this.state.location,
          setLocation: location => this.setLocation(location),

          brands: this.state.brands,
          updateStationBrand: brandid => this.updateStationBrand(brandid),

          station: this.state.station,
          stations: this.state.stations,
          selectStation: idstations => this.selectStation(idstations),

          updateStationKey: (key, value) => this.updateStationKey(key, value),
          setOpenClosed: (day, open, closed) => this.setOpenClosed(day, open, closed),

          email: this.state.email,
          emailValid: this.state.emailValid,
          setEmail: email => this.setState({email, stripeError: null, emailValid: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase())}),

          idstations: this.state.idstations,
          setCountryCode: countryCode => this.setState({countryCode}),

          setPhoneOther: phoneOther => this.setState({phoneOther}),
          phoneOther: this.state.phoneOther,

          welcomePack: this.state.welcomePack,
          setWelcome: welcomePack => this.setState({welcomePack}),

          checkout: () => this.checkout(),
          checkCancel: () => this.checkCancel(),

          success: () => this.success(),
          lastSignedUp: this.state.lastSignedUp,

          siteSettings: this.state.siteSettings,

          stripeError: this.state.stripeError          

        }}>
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}

export default AppStateContext;
