export const getCurrencyFromCountry = (country) => {
    switch (country.toLowerCase()) {

        case 'united kingdom':
        case 'uk':
            return 'GBP';
        
        case 'austria':
        case 'belgium':
        case 'cyprus':
        case 'estonia':
        case 'finland':
        case 'france':
        case 'germany':
        case 'greece':
        case 'ireland':
        case 'italy':
        case 'latvia':
        case 'lithuania':
        case 'luxembourg':
        case 'malta':
        case 'netherlands':
        case 'portugal':
        case 'spain':
        case 'slovenia':
        case 'slovakia':
            return 'EUR';

        case 'united states':
            return 'USD';

        case 'canada':
            return  'CAD';

        default:
            return 'USD';
    }
}