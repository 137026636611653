import React from 'react';

import AppStateContext from '../AppStateContext';
import { TimeSummary } from './Time/TimeSummary';

import FuelAvailable from './FuelTypes/FuelAvailable';
import PaymentAvailable from './Payment/PaymentAvailable';
import ServiceAvailable from './Services/ServiceAvailable';

export class Finish extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {

        const openingTimes = TimeSummary(this.context.station);

        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div className="signup-summary-page">

                        {appState.stripeError && <div className="signup-summary-error">{appState.stripeError}</div>}
                        
                        <div className="signup-main-title">All that's left to do is</div>

                        <div className="signup-payment-container">
                            <button className="checkout-button" onClick={()=>appState.checkout()}>
                                Checkout
                            </button>
                        </div>

                        <div className="signup-summary-container">
                            <div className="signup-summary-row">
                                <div className="signup-summary-label">Name</div>
                                <div className="signup-summary-item">{appState.station.name}</div>
                            </div>
                            <div className="signup-summary-row">
                                <div className="signup-summary-label">Address</div>
                                <div className="signup-summary-item">{appState.station.address1}</div>
                            </div>
                            <div className="signup-summary-row">
                                <div className="signup-summary-label">Phone</div>
                                <div className="signup-summary-item">{appState.station.phone1}</div>
                            </div>
                            <div className="signup-summary-row">
                                <div className="signup-summary-label">Available</div>
                                <div className="signup-summary-item">
                                    {openingTimes.map((time,index) =>
                                        <div className="signup-summary-time-item" key={index}>
                                            <div className="signup-summary-time-day">{time[0]}</div>
                                            <div className="signup-summary-time-time">{time[1]}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="signup-summary-row">
                                <div className="signup-summary-label">Fuel</div>
                                <div className="signup-summary-item">
                                    {FuelAvailable(appState.station).map((fuelName, index, allNames) =>
                                        <div className="signup-summary-fuel-item" key={index}>
                                            {fuelName}{index < (allNames.length - 1) && <span className="hide-mobile">&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="signup-summary-row">
                                <div className="signup-summary-label">Payment</div>
                                <div className="signup-summary-item">
                                    {PaymentAvailable(appState.station).map((payment, index, allPayments) =>
                                        <div className="signup-summary-fuel-item" key={index}>
                                            {payment}{index < (allPayments.length - 1) && <span className="hide-mobile">&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="signup-summary-row">
                                <div className="signup-summary-label">Services</div>
                                <div className="signup-summary-item">
                                    {ServiceAvailable(appState.station).map((service, index, allServices) =>
                                        <div className="signup-summary-fuel-item" key={index}>
                                            {service}{index < (allServices.length - 1) && <span className="hide-mobile">&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                       
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Finish.contextType = AppStateContext;
export default Finish;    