import React from 'react';

import AppStateContext from '../AppStateContext';

import AwesomeSlider from 'react-awesome-slider';
import { Link } from 'react-router-dom';

import './Home.css';
import HomeNavButtons from './HomeNavButtons';

const LogoImage = require('./Images/logo-white.svg');
const MaxolLogoImage = require('./Images/Maxol-no-background.svg');
const IPhoneImage = require('./Images/iPhone-6s-Silver-vertical.png');
const OfficePhoneImage = require('./Images/modern-office-telephone-icon.png');
const PumpLogo = require('./Images/logo-white-on-transparent-128.png');
const WheelchairImage = require('./Images/wheelchair.svg');

export class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfSlides: 0,
            selectedSlide: 0,
        }
    }

    componentDidMount() {
        // Preload the images
        const imageList = [LogoImage, MaxolLogoImage, IPhoneImage, WheelchairImage, OfficePhoneImage, PumpLogo];
        imageList.forEach((image) => {
            new Image().src = image
        });
    }
    render() {
        return (
                <div className="home-page animate__animated animate__fadeIn">
                    <div className="home-topbar">
                        <div className="top-bar-logo-container">
                            <img className="top-bar-logo hide-mobile" src={LogoImage} alt="fuelService Logo"/>
                            <img className="top-bar-logo" src={MaxolLogoImage} alt="Maxol Logo" />
                        </div>
                        <Link className="signup-button signup-button-topbar" to='/signup'>Sign Up</Link>
                    </div>
                    <div className="home-slider-container">
                        <AwesomeSlider
                            name="home-slider"
                            infinite={false}
                            buttons={false}
                            selected={this.state.selectedSlide}
                            bullets={false}
                            onFirstMount={(event)=>this.setState({numberOfSlides: event.slides, selectedSlide: event.currentIndex})}
                            onTransitionEnd={(event)=>this.setState({selectedSlide: event.currentIndex})}
                            fillParent={true}
                        >

                            <div><Intro /></div>
                            <div><Why /></div>
                            <div><How /></div>
                            <div><What /></div>
                            <div><Summary /></div>

                        </AwesomeSlider>
                    </div>
                    <div className="home-bottombar">
                        <HomeNavButtons
                            numberOfSlides={this.state.numberOfSlides}
                            selectedSlide={this.state.selectedSlide}
                            gotoNext={()=>this.setState({selectedSlide: this.state.selectedSlide + 1})}
                            gotoPrevious={()=>this.setState({selectedSlide: this.state.selectedSlide - 1})}
                        />
                    </div>
                </div>
        );
    }
  }

Home.contextType = AppStateContext;
export default Home;


const Intro = () => 
        <div className="main row">
            <div className="main-left">
                <img className="main-app-image" src={IPhoneImage} alt="fuelService app"/>
            </div>
            <div className="main-right">
                <img className="main-pump-logo-image" src={PumpLogo} alt="fuelService pump logo"/>
                <h1 className="main-heading">Say YES to Disabled Drivers</h1>
                <div className="main-message">Solve all the challenges for disabled drivers</div>
                <div className="main-message">Be up and running in 10 minutes</div>
            </div>
        </div>
        
const Why = () => 
        <div className="main row">
            <div className="main-left">
                <img className="main-app-image" src={WheelchairImage} alt="wheelchair"/>
            </div>
            <div className="main-right">
                <h1 className="main-heading">Disabled Drivers need YOUR help</h1>
                <div className="bullet-title">They need to:</div>
                <ul className="bullets">
                    <li>Ask where they can get help before they go.</li>
                    <li>Let you know when they arrive.</li>
                </ul>
                <div className="bullet-title">Why though?</div>
                <ul className="bullets">
                    <li>Beeping the horn is humiliating</li>
                    <li>What happens if no one comes?</li>
                </ul>
            </div>
        </div>

const How = () => 
        <div className="main row">
            <div className="main-left">
                <img className="main-app-image" src={IPhoneImage} alt="fuelService app"/>
            </div>
            <div className="main-right">
                <h1>fuelService solves the problem</h1>
                <ul className="bullets">
                    <li>Let's drivers ask before they go</li>
                    <li>Tells you when they arrive</li>
                    <li>Let's them leave feedback</li>
                    <li>Meet your regulatory obligations</li>
                </ul>
            </div>
        </div>

const What = () => 
        <div className="main row">
            <div className="main-left">
                <img className="main-app-image" src={OfficePhoneImage} alt="Landline Phone"/>
            </div>
            <div className="main-right">
                <h1>How does it work?</h1>
                <ul className="bullets">
                    <li>You don't need to install any equipment.</li>
                    <li>It uses your phone to contact you.</li>
                    <li>If you can help, press 1.<br/>If you can't press 2.<br/>Thats it!</li>
                    <li>We provide signage for your stations &amp; training instructions for your staff</li>
                </ul>
            </div>
        </div>

const Summary = () => 
        <div className="main row">
            <div className="main-left">
                <SummaryPricingTable />
            </div>
            <div className="main-right">
                <img className="main-pump-logo-image hide-mobile" src={PumpLogo} alt="fuelService pump logo"/>
                <div className="hide-full-screen">
                    <SummaryPricingTable />
                </div>
                
                <h1 className="hide-mobile">Sign Up Today</h1>
                <div className="main-message">We can have you up and running in 10 minutes</div>
                
                <Link className="signup-button" to='/signup'>Sign Up Now</Link>
            </div>
        </div>

const SummaryPricingTable = () =>
    <div className="home-pricing-container">
        <div className="home-pricing-title">
            Everything You Need
        </div>
        <div className="home-pricing-item">
            Staff Training Guide
        </div>
        <div className="home-pricing-item">
            Stanchion Stickers
        </div>
        <div className="home-pricing-item">
            Usage &amp; Feedback Portal
        </div>
        <div className="home-pricing-item">
            24/7 Support
        </div>
        <div className="home-pricing-item home-pricing-item-last">
            All costs included
        </div>
        <div className="home-pricing-price">
            <div className="home-pricing-price-value">
                €120 per year (IE)<br/>
                £100+VAT (NI) per year
            </div>
            <div className="home-pricing-price-subtext">
                * Payable annually
            </div>
        </div>

    </div>