import React from 'react';

import "./TextInput.css";

const TextInput = (props) => {

    const {label, hint, ...other} = props;
    return (
        <div className="text-input-container">
            {label && <label className="text-input-label">{label}</label>}
            <div className="text-input-right-container">
                <input className="text-input-input" {...other} />
                {hint && <div className="text-input-hint">{hint}</div>}
            </div>
        </div>
        
    );

}

export default TextInput;