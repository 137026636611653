import React from 'react';

import AppStateContext from '../AppStateContext';
import TextInput from './FormComponents/TextInput';
import TextArea from './FormComponents/TextArea';

export class GetUser extends React.Component {

    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div className="signup-page-contents-container">
                        <div className="signup-main-title">Nearly done</div>

                        <TextInput
                            label="Your email" 
                            placeholder="email@domain.com"
                            value={appState.email}
                            onChange={(event)=>appState.setEmail(event.target.value)}/>
                        <TextArea
                            rows="5"
                            label="Welcome pack"
                            placeholder="We normally send it to the station, do you want it sending somewhere else?"
                            value={appState.welcomePack}
                            onChange={(event)=>appState.setWelcome(event.target.value)}/>
                                
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

GetUser.contextType = AppStateContext;
export default GetUser;    