import React from 'react';

import AppStateContext from '../AppStateContext';


// import Countries from '../services/countries';
import TextInput from './FormComponents/TextInput';
// import SelectInput from './FormComponents/SelectInput';

export class Address extends React.Component {

    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div className="signup-page-contents-container">
                        <div className="signup-main-title">Confirm the address</div>

                        <TextInput label="Address 1" disabled={!appState.siteSettings.permissions.changeAddress1} value={appState.station.address1} onChange={event=>appState.updateStationKey('address1', event.target.value)} />

                        <TextInput label="Address 2" disabled={!appState.siteSettings.permissions.changeAddress2} value={appState.station.address2} onChange={event=>appState.updateStationKey('address2', event.target.value)} />

                        <TextInput label="Town" disabled={!appState.siteSettings.permissions.changeTown} value={appState.station.town} onChange={event=>appState.updateStationKey('town', event.target.value)} />

                        <TextInput label="City" disabled={!appState.siteSettings.permissions.changeCity} value={appState.station.city} onChange={event=>appState.updateStationKey('city', event.target.value)} />

                        <TextInput label="Post/Zip Code" disabled={!appState.siteSettings.permissions.changePostcode} value={appState.station.postcode} onChange={event=>appState.updateStationKey('postcode', event.target.value)} />

                        {/* <SelectInput label="Country" value={appState.station.country} onChange={event=>appState.updateStationKey('country', event.target.value)}>
                            {Countries.map(country =>
                                <option key={country.code} value={country.name}>
                                    {country.name}
                                </option>
                            )}
                        </SelectInput> */}

                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Address.contextType = AppStateContext;

export default Address;    