import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';
import 'react-awesome-slider/dist/styles.css';
import './awesome-slider.css'; // overrides

import {AppStateProvider} from './AppStateContext';

import Home from './Home/Home';
import Success from './Success/Success';
import SignUp from './SignUp/SignUp';

function App() {
  return (
    <AppStateProvider>
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/success">
              <Success />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </AppStateProvider>
  );
}

export default App;
