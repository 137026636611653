import React from 'react';

import AppStateContext from '../AppStateContext';

import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';

import './FindStation.css';

export class FindStation extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    stationSelected(place) {
        geocodeByPlaceId(place.value.place_id)
        .then(results => {
            const location = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
            }
            this.context.setLocation(location);
        })
        .catch(error => {
            console.error(error)
        });
    }

    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div>
                        <div className="signup-main-title">Find your station using its address</div>
                        <div className="signup-main-text">Start typing the address or town and select it from the list</div>
                        <div className="google-places-autocomplete">
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyBCg421_CZzKqqntngiacel0wzr1U0Ryak"
                            selectProps={{
                                onChange: (place) => this.stationSelected(place),
                                placeholder: "Address...",
                                styles: {
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        display: 'none',
                                      }),
                                    indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: 'none',
                                        }),
                                    input: (provided) => ({
                                      ...provided,
                                      color: '##0f0f0f',
                                    }),
                                    option: (provided) => ({
                                      ...provided,
                                      color: 'color: #0f0f0f',
                                    }),
                                    singleValue: (provided) => ({
                                      ...provided,
                                      fontWeight: 600
                                    }),
                                  },
                            }}

                            autocompletionRequest={{
                                types: [
                                    'geocode'
                                ],
                                componentRestrictions: {
                                    country: appState.siteSettings.countries,
                                }
                              }}
                        />
                        </div>
                       
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

FindStation.contextType = AppStateContext;
export default FindStation;    