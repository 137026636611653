import React from 'react';

import AppStateContext from '../AppStateContext';

import './SelectStation.css';

export class SelectStation extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div className="selectstation-page">
                        <div className="signup-main-title">Choose your station</div>

                        <div className="selectstation-all-stations-container">
                            {appState.stations.map(station =>
                                <div className="selectstation-station-container" key={station.idstations} onClick={()=>appState.selectStation(station.idstations)}>
                                    <div><img alt={station.brandid} src={`https://fuelservice.org/brandimages/${station.brandid}.png`} /></div>
                                    <div className="selectstation-station-details">
                                        <div className="selectstation-station-name">{formatName(station.brand, station.name)}</div>
                                        <div className="selectstation-station-address">{formatAddress([station.address1, station.address2, station.town, station.city, station.postcode])}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </AppStateContext.Consumer> 
        );
    }
  }

SelectStation.contextType = AppStateContext;
export default SelectStation;    

const formatName = (brand, name) => {
    if(name.toLowerCase().startsWith(brand)) {
        return (name);
    } else {
        return (`${brand.toUpperCase()} ${name}`);
    }
}

const formatAddress = (address) => {
    // remove items not included
    address = address.filter(item => !!item);
    // concatenate items with ', ' inbetween
    return(address.join(', '));
}