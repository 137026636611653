import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class SignUpNavButtons extends React.Component {

    render() {
        return (
            <div className="bottom-nav-buttons">
                {this.props.selectedSlide !== 0 && <button className="bottom-nav-back-button" disabled={this.props.prevDisabled} onClick={()=>this.props.gotoPrevious()}><FontAwesomeIcon size="xs" icon={["fal", "chevron-left"]}/>&nbsp;&nbsp;Back</button>}
                {this.props.selectedSlide === 0 && <div />}

                {this.props.selectedSlide < (this.props.numberOfSlides - 1) // not the last slide
                    && this.props.selectedSlide > 1 // not the first two slides
                    && <button className="bottom-nav-next-button" disabled={this.props.nextDisabled} onClick={()=>this.props.gotoNext()}>Next&nbsp;&nbsp;<FontAwesomeIcon size="xs" icon={["fal", "chevron-right"]}/></button>}
                {this.props.selectedSlide === 0 && <div />}
            </div>
        );
    }

  }

export default SignUpNavButtons;    