import React from 'react';

import AppStateContext from '../AppStateContext';
import Checkbox from './FormComponents/Checkbox';

const ServiceTypes = require('./Services/ServiceTypes.json');

export class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesTypes: ServiceTypes
        }
    }
    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div>
                        <div className="signup-main-title">Which other services does your station have?</div>

                        {this.state.servicesTypes.map(service =>
                            <Checkbox
                                key={service.key}
                                label={service.label}
                                checked={appState.station[service.key] === 1}
                                onChange={event => appState.updateStationKey(service.key, event.target.checked ? 1: 0)}
                            />
                        )}
                        
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Services.contextType = AppStateContext;
export default Services;