import React from 'react';

import AppStateContext from '../AppStateContext';
import Checkbox from './FormComponents/Checkbox';

const FuelTypes = require('./FuelTypes/FuelTypes.json');

export class Fuel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fuelTypes: FuelTypes.default
        }
    }

    componentDidMount() {
        if(FuelTypes[this.context.station.country]) {
            this.setState({fuelTypes: FuelTypes[this.context.station.country]});
        } else {
            this.setState({fuelTypes: FuelTypes.default});
        }
    }
    
    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div>
                        <div className="signup-main-title">Which kinds of fuel do you offer?</div>

                        <div className="signup-center-content">
                            {this.state.fuelTypes.map(fuel =>
                                <Checkbox
                                    key={fuel.key}
                                    label={fuel.label}
                                    checked={appState.station[fuel.key] === 1}
                                    onChange={event => appState.updateStationKey(fuel.key, event.target.checked ? 1: 0)}
                                />
                            )}
                        </div>
                        
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Fuel.contextType = AppStateContext;
export default Fuel;