import React from 'react';

import './HomeNavButtons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class HomeNavButtons extends React.Component {

    render() {
        return (

            <div className="bottom-nav-buttons">
                {this.props.selectedSlide !== 0 && <button className="bottom-nav-back-button" disabled={this.props.prevDisabled} onClick={()=>this.props.gotoPrevious()}><FontAwesomeIcon size="xs" icon={["fal", "chevron-left"]}/>&nbsp;&nbsp;Back</button>}
                {this.props.selectedSlide === 0 && <div />}
                {this.props.selectedSlide < (this.props.numberOfSlides - 1) && <button className="bottom-nav-next-button" disabled={this.props.nextDisabled} onClick={()=>this.props.gotoNext()}>Next&nbsp;&nbsp;<FontAwesomeIcon size="xs" icon={["fal", "chevron-right"]}/></button>}
            </div>

        );
    }
  }

export default HomeNavButtons;    