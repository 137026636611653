import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Add each icon you want to use here

// Import others weights with the same name and include the as in the fontawesome library.add - then use with 
// import { faHome as faHomeLight } from '@fortawesome/pro-light-svg-icons/faHome';
// To put in the jsx use
// <FontAwesomeIcon icon={["fal", "home"]}/>
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faShare } from '@fortawesome/pro-light-svg-icons/faShare';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';

library.add(
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faShare,
    faEdit,
    faCopy
);


export default FontAwesomeIcon;