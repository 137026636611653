import React from 'react';

import "./Checkbox.css";

const Checkbox = (props) => {

    const {label, hint, ...other} = props;
    return (
        <div className="checkbox-input-container">
            <input
                    type="checkbox"
                    className="checkbox-input-input"
                    {...other}
                />
            
            <div className="checkbox-input-right-container">
                <label className="checkbox-input-label">{label}</label>
                {hint && <div className="checkbox-input-hint">{hint}</div>}
            </div>
        </div>
        
    );

}

export default Checkbox;