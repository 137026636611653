import React from 'react';

import AppStateContext from '../AppStateContext';
import Checkbox from './FormComponents/Checkbox';

const PaymentTypes = require('./Payment/PaymentTypes.json');

export class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentTypes: PaymentTypes
        }
    }
    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div>
                        <div className="signup-main-title">Which kinds of payment do you accept?</div>

                        {this.state.paymentTypes.map(payment =>
                            <Checkbox
                                key={payment.key}
                                label={payment.label}
                                checked={appState.station[payment.key] === 1}
                                onChange={event => appState.updateStationKey(payment.key, event.target.checked ? 1: 0)}
                            />
                        )}
                        
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Payment.contextType = AppStateContext;
export default Payment;