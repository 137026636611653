import React from 'react';
import FontAwesomeIcon from '../../FontAwesome';

import './TimeItem.css';

export class TimeItem extends React.Component {
    constructor(props) {
        super(props);

        const initial = this.parseTime();
        this.state = {
            hours: initial.hours,
            minutes: initial.minutes,
            seconds: initial.seconds,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const parsedTime = props.time.split(':');
        if(parseInt(parsedTime[0]) !== state.hours ||
            parseInt(parsedTime[1]) !== state.minutes ||
            parseInt(parsedTime[2]) !== state.seconds) {
                return({
                    hours: parsedTime[0],
                    minutes: parsedTime[1],
                    seconds: parsedTime[2]
                });
        }
        return(null);
    }

    increaseHours() {
        const current = this.parseTime();
        current.hours++
        if(current.hours > 23) {
            current.hours = 23;
        }
        this.setTime(current);
    }

    decreaseHours() {
        const current = this.parseTime();
        current.hours--;
        if(current.hours < 0) {
            current.hours = 0;
        }
        this.setTime(current);
    }

    increaseMinutes() {
        const current = this.parseTime();
        current.minutes++;
        if(current.minutes > 59) {
            current.minutes = 0;
        }
        this.setTime(current); 
    }

    decreaseMinutes() {
        const current = this.parseTime();
        current.minutes--;
        if(current.minutes < 0){
            current.minutes = 59;
        }
        this.setTime(current);   
    }

    parseTime() {
        const time = this.props.time.split(':');
        return ({
            hours: parseInt(time[0]),
            minutes: parseInt(time[1]),
            seconds: parseInt(time[2])
        })
    }

    setTime(time) {
        if(this.props.setTime) {
            this.props.setTime(
                (time.hours < 10 ? '0' + time.hours : time.hours) + ':' +
                (time.minutes < 10 ? '0' + time.minutes : time.minutes) + ':' +
                ('00')
            );
        }
    }
    
    render() {

        const time = this.parseTime();

        return (
            <div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="timeitem-column">
                        <div onClick={()=>this.increaseHours()} className="timeitem-control"><FontAwesomeIcon icon={["fal", "chevron-up"]}/></div>
                        <div className="timeitem-value">{time.hours < 10 ? '0' + time.hours : time.hours}</div>
                        <div onClick={()=>this.decreaseHours()} className="timeitem-control"><FontAwesomeIcon icon={["fal", "chevron-down"]}/></div>
                    </div>
                    <div className="timeitem-seperator-container">
                        <div></div>
                        <div className="timeitem-separator">:</div>
                        <div></div>
                    </div>
                    <div className="timeitem-column">
                        <div onClick={()=>this.increaseMinutes()} className="timeitem-control"><FontAwesomeIcon icon={["fal", "chevron-up"]}/></div>
                        <div className="timeitem-value">{time.minutes < 10 ? '0' + time.minutes : time.minutes}</div>
                        <div onClick={()=>this.decreaseMinutes()} className="timeitem-control"><FontAwesomeIcon icon={["fal", "chevron-down"]}/></div>
                    </div>
                </div>
            </div>
        );
    }
  }

export default TimeItem;