import React from 'react';

import "./Radio.css";

const Radio = (props) => {

    const {hint, value, label, name, options, onChange} = props;
    return (
        <div className="radio-input-container">
            <label className="radio-input-label">{label}</label>
            <div className="radio-input-items-container">
                {options.map((option, index) => 
                    <div className="radio-input-item" key={index}>
                        <input type="radio" name={name} value={option.value} checked={value === option.value} onChange={onChange}/>
                        <label onClick={()=>onChange({target:{value:option.value}})}>{option.label}</label>
                    </div>
                )}
                {hint && <div className="radio-input-hint hide-mobile">{hint}</div>}
            </div>
            {hint && <div className="radio-input-hint hide-full-screen">{hint}</div>}
        </div>
      
    );

}

export default Radio;