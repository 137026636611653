import React from 'react';

import AppStateContext from '../AppStateContext';
import ChangeTime from './Time/ChangeTime';

import './Opening.css';

const days = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];

export class Opening extends React.Component {
    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div>
                        <div className="signup-main-title">When is the station open?</div>
                        <div className="signup-main-text">Or when will you be able to offer assistance?</div>

                        <div className="opening-times-container">
                            {days.map(day =>
                                <div className="opening-day-container" key={day}>
                                    <div className="opening-day-dayname">
                                        {titleCase(day)}
                                    </div>
                                    <div className="opening-day-time">
                                        {hourMinute(appState.station[day + 'open'])} - {hourMinute(appState.station[day + 'close'])}
                                    </div>
                                    <div className="opening-day-buttons">
                                        <ChangeTime day={day} open={appState.station[day + 'open']} close={appState.station[day + 'close']} />
                                    </div>
                                </div>    
                            )}
                        </div>
                        
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Opening.contextType = AppStateContext;
export default Opening;

const titleCase = (string) => (string.charAt(0).toUpperCase() + string.slice(1));
const hourMinute = (string) => string.substr(0,5);
