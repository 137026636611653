let hostname = 'fuelservice.org';
if(window.location.host.toUpperCase().includes('LOCALHOST')
    || window.location.host.toUpperCase().includes('127.0.0.1')
    || window.location.host.toUpperCase().includes('192.168.1.200')
    || window.location.host.toUpperCase().includes('DEV.FUELSERVICE.ORG')
    ) {
    hostname = 'dev.fuelservice.org';
    console.log('*** USING DEV.FUELSERVICE.ORG ***');
}

export default hostname;