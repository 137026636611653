export const GetSiteSettings = () => {
    const hostname = window.location.hostname.toLowerCase();
    
    for (let i=0; i < siteMapping.length; i++) {
        if(siteMapping[i].hostname.toLowerCase() === hostname) {
            // console.log(hostname);
            // console.log(sites[siteMapping[i].site]);
            return (sites[siteMapping[i].site]);
        }
    }

    return(sites.default);
}

const siteMapping = [
    {
        hostname: 'localhost',
        site: 'maxol'
    },
    {
        hostname: '192.168.1.200',
        site: 'maxol'
    },
    {
        hostname: 'maxol.fuelservice.org',
        site: 'maxol'
    }
];

const sites = {
    default: {
        brandid: 'maxol', // limit to a brandid
        searchRadius: 10,
        countries: [],  // limit to a country for google places lookup (empty is all countries)
    },

    maxol: {
        brandid: 'maxol',
        searchRadius: 10,
        countries: ['ie', 'uk'],
        permissions: {
            changeName: true,
            changeBrand: false,
            changeAddress1: true,
            changeAddress2: true,
            changeTown: true,
            changeCity: true,
            changePostcode: true
        }
    }
}

export default GetSiteSettings;