import React from 'react';

import AppStateContext from '../AppStateContext';

import TextInput from './FormComponents/TextInput';
import Radio from './FormComponents/Radio';
import TextArea from './FormComponents/TextArea';

const answeringMachineOptions = [
    {label: 'Yes', value: 1},
    {label: 'No', value: 0}
]

export class Phone extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                     <div>
                        <div className="signup-main-title">Confirm the phone number for the station.</div>
                        
                        <div>
                            <TextInput label="Phone Number" value={appState.station.phone1} onChange={event=>appState.updateStationKey('phone1', event.target.value)} />
                        </div>

                        <div>
                            <Radio
                                label="Answering Machine"
                                name="answeringmachine-radio"
                                options={answeringMachineOptions}
                                value={appState.station.answerphone1}
                                onChange={event => appState.updateStationKey('answerphone1', parseInt(event.target.value,10))}
                                hint="Is there an answering machine or voicemail on this number"
                            />
                        </div>

                        <div>
                            <TextArea 
                                rows="3"
                                label="Anything Else"
                                hint="Does this number go to a menu system? If so what keys need pressing to get to the station"
                                value={appState.phoneOther}
                                onChange={event=>appState.setPhoneOther(event.target.value)} />
                        </div>
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
  }

Phone.contextType = AppStateContext;
export default Phone;

// const formatPhone = (country, phonenumber) => { // formats the phone number for insertion into database
//     // phoneCountryCode = this.countryService.getCountryPhoneCodeByName(country);

//     // remove all non digits
//     const countryCode = phoneCountryCode.replace(/\D/g, '');
//     const phone = phonenumber.replace(/\D/g, '');

//     // if they entered the whole phone number including a +
//     if (phonenumber.charAt(0) === '+') {
//         return ('+' + phone);
//     } else { // add the country code
//         return ('+' + countryCode + parseInt(phone, 10));
//     }
// }

//   showPhoneCountryCode() { // shows the phone country code in brackets if the country is selected
//     if (this.phoneCountryCode) {
//       return(`(${this.phoneCountryCode})`);
//     }
//   }