export const TimeSummary = (station) => {

    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    let timeString = '';

    let startDay = 0;
    let endDay = 0;

    let times = [{
            open: station.mondayopen.substr(0, 5),
            close: station.mondayclose.substr(0, 5)
        },
        {
            open: station.tuesdayopen.substr(0, 5),
            close: station.tuesdayclose.substr(0, 5)
        },
        {
            open: station.wednesdayopen.substr(0, 5),
            close: station.wednesdayclose.substr(0, 5)
        },
        {
            open: station.thursdayopen.substr(0, 5),
            close: station.thursdayclose.substr(0, 5)
        },
        {
            open: station.fridayopen.substr(0, 5),
            close: station.fridayclose.substr(0, 5)
        },
        {
            open: station.saturdayopen.substr(0, 5),
            close: station.saturdayclose.substr(0, 5)
        },
        {
            open: station.sundayopen.substr(0, 5),
            close: station.sundayclose.substr(0, 5)
        }
    ];


    for (let i = 1; i < times.length; i++) {

        if ((times[i - 1].open !== times[i].open) || (times[i - 1].close !== times[i].close)) { // if the times don't match then write out the previous times
            if (startDay === endDay) {
                timeString += days[startDay] + '%';
            } else {
                timeString += days[startDay] + '-' + days[endDay] + '%';
            }
            if (times[i - 1].open === times[i - 1].close) {
                timeString += 'Not Available,';
            } else {
                timeString += times[i - 1].open + '-' + times[i - 1].close + ',';
            }
            // reset the start and end days to be the current day
            startDay = i;
            endDay = i;
        } else { // the times matched
            endDay = i; // set the end date to be the current day
        }
        if (endDay === times.length - 1) { // if processing sunday then write out the sunday times
            if (startDay === endDay) {
                timeString += days[startDay] + '%';
            } else {
                timeString += days[startDay] + '-' + days[endDay] + '%';
            }
            if (times[i].open === times[i].close) {
                timeString += 'Not Available';
            } else {
                timeString += times[i].open + '-' + times[i].close;
            }

        }


    }

    const timeArray = timeString.split(',');
    for(let i=0; i < timeArray.length; i++) {
        timeArray[i] = timeArray[i].split('%');
    }

    return(timeArray);

}