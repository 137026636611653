import React from 'react';
import { Link } from 'react-router-dom';

import AppStateContext from '../AppStateContext';

import './Success.css';

// const LogoImage = require('../Home/Images/logo-white.svg');
const PumpLogo = require('../Home/Images/logo-white-on-transparent-128.png');

export class Success extends React.Component {

    componentDidMount() {
        this.context.success();
    }

    render() {
        return (
            <AppStateContext.Consumer>
                {(appState) => (
                    <div className="success-page animate__animated animate__fadeIn">
                        <div className="success-heading">Thank You</div>
                        <img className="success-fuelservice-pump-logo" src={PumpLogo} alt="fuelService Logo"/>
                        {/* <img className="success-fuelservice-word-logo" src={LogoImage} alt="fuelService Logo"/> */}
                        {appState.lastSignedUp &&
                            <div className="success-message-container">
                                <div className="success-message"><strong>{appState.lastSignedUp.name}</strong> has been added to <strong>fuelService</strong>.</div>
                                <div className="success-message">We are preparing the welcome pack with training instructions and signage which will be sent out asap.</div>
                                <div className="success-message">
                                    We will send you an email with training instructions, and if you want the station turning on for users sooner
                                    - just reply to the email saying when.
                                    </div>
                                <div className="success-message">
                                    If you need any changes, or help at any time please email us at <a href={"mailto:support@fuelservice.org?subject=" + appState.lastSignedUp.name + " Signup"}>support@fuelservice.org</a>
                                </div>

                                <Link className="success-add-another-button" to='/signup'>Add another station</Link>
                            </div>
                        }
                    </div>
                )}
            </AppStateContext.Consumer>
        );
    }
}

Success.contextType = AppStateContext;

export default Success;